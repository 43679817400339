import styled from 'styled-components';

import typography from '../styles/typography';
import palette from '../styles/palette';
import media from '../styles/media';

const Container = styled.p<{ alert : boolean }>`
  ${typography.caption}
  color: ${({ alert }) => (alert ? palette.textAlert : palette.textDarkGrey)};
  ${media.isMobile`
    ${typography.caption}
  `}
`;

export default function TextLengthMonitor({ text, maxLength, recommendedLength }: {
  text: string;
  maxLength: number;
  recommendedLength: number;
}) {
  const isMaxLength = text.length === maxLength;
  return (
    <Container alert={isMaxLength}>
      {text.length}
      /
      {maxLength}
      자 (
      {recommendedLength}
      자 이상 권장)
    </Container>
  );
}
