import styled from 'styled-components';

import palette from '../styles/palette';
import typography from '../styles/typography';
import media from '../styles/media';

import assetSource from '../data/assetSource';

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  width: 2em;

  ${media.isMobile`
    width: 1.5em;
  `}
`;

export const SidebarIcon = styled.div`
  ${typography.heading1}
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette.primary};

  ${media.isMobile`
    ${typography.heading1Mobile}
  `}
`;

const SmallSidebarIcon = styled.div`
  ${typography.heading2}
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette.primary};

  ${media.isMobile`
    ${typography.heading2Mobile}
  `}  
`;

export const SidebarLine = styled.div`
  width: 1px;
  height: 100%;
  background: ${palette.containerLightGrey};
`;

export const SidebarImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 50%;
    border: 0.05px solid #D9D9D9;
    object-fit: cover;
`;

export default function Sidebar() {
  return (
    <SidebarContainer>
      <SidebarIcon>
        Q
      </SidebarIcon>
      <SidebarLine />
    </SidebarContainer>
  );
}

export function SmallAnswerIconSidebar() {
  return (
    <SidebarContainer>
      <SmallSidebarIcon>
        A
      </SmallSidebarIcon>
      <SidebarLine />
    </SidebarContainer>
  );
}

export function AnswerSidebar() {
  return (
    <SidebarContainer>
      <SidebarImage src={`${assetSource}/assets/benjamin.png`} alt="벤자민" />
      <SidebarLine />
    </SidebarContainer>
  );
}
