import styled from 'styled-components';
import media from '../../styles/media';

const ChatPageLayout = styled.div`
  height: calc(100vh - 11em);
  padding-inline: 8em;
  
  ${media.isMobile`
    height: calc(100svh - 3.375em);
    padding: 0;
  `}
`;

export default ChatPageLayout;
