import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

import { ChangeEvent, useEffect, useRef } from 'react';
import palette from '../styles/palette';
import typography from '../styles/typography';
import media from '../styles/media';

import assetSource from '../data/assetSource';

import useMainConsultation from '../hooks/useMainConsultation';
import useConsultation from '../hooks/useConsultation';
import useInquiryAutoWrite from '../hooks/useInquiryAutoWrite';

import ChatPageLayout from '../components/chat/ChatPageLayout';
import TextLengthMonitor from '../components/TextLengthMonitor';
import Chat from '../components/consultation/Chat';

export const ChatContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2em 0.5em 2em;
  border-radius: 10px;
  border: 1px solid ${palette.containerLightGrey};
  
  ${media.isMobile`
    border: none;
    padding: 0;
  `}
`;

export const QuestionForm = styled.div<{ noBorderTop: boolean; }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5em;
  padding-top: 0.5em;
  border-top: ${({ noBorderTop }) => (noBorderTop ? 'none' : `1px solid ${palette.containerLightGrey}`)};

  ${media.isMobile`
    flex-direction: column-reverse;
    border-top: 1px solid ${palette.containerLightGrey};
    padding: 0.5em 0.75em;
  `}
`;

export const QuestionInput = styled.div`
  color: ${palette.textBlack};
  width: 100%;
  display: flex;
  gap: 0.5em;
  align-items: center;
  border-radius: 25px;
  background: ${palette.containerSuperLightGrey};
  padding: 0.375em 0.25em 0.375em 1.5em;
  position: relative;

  ${media.isMobile`
    border-radius: 16px;
    padding: 0.25em 0.25em 0.25em 1em;
  `}

  textarea {
    width: 100%;
    ${typography.body1}
    background: ${palette.containerSuperLightGrey};
    padding: 0;
    resize: none;
    border: none;
    outline: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &::placeholder {
      color: ${palette.textGrey};
    }
  }

  ${media.isMobile`
    border-radius: 16px;
    padding: 0.25em 0.25em 0.25em 1em;
  `}
`;

export const QuestionSubmit = styled.div<{ inactive: boolean }>`
  height: 100%;
  display: flex;
  align-items: flex-end;
  
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    aspect-ratio: 1;
    background-color: ${({ inactive }) => (inactive ? 'transparent' : palette.primary)};
    padding: 0;
    border-radius: 25px;
    cursor: ${({ inactive }) => (inactive ? 'default' : 'pointer')};
    pointer-events: ${({ inactive }) => (inactive ? 'none' : 'auto')};
  }
`;

export const ChatHistory = styled.div`
  padding-block: 2em;
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  gap: 2em;
  overflow-y: scroll;
  
  &::-webkit-scrollbar {
    display: none;
  }

  ${media.isMobile`
    padding: 0.75em;
    gap: 1em;
  `}
`;

export default function ChatHomePage() {
  const {
    consult, updateContent, inquiry, isAdvising, recommendedContentLength, contentMaxLength,
  } = useMainConsultation();
  const { initialize } = useConsultation();
  const { autoWrite } = useInquiryAutoWrite();

  useEffect(() => {
    initialize();
    autoWrite(updateContent);
  }, [autoWrite, initialize, updateContent]);

  const focusedChatRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (focusedChatRef.current) {
      focusedChatRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isAdvising]);

  const handleChangeContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    updateContent(value);
  };

  const instruction = `안녕하세요, ***AI 변호사 벤자민***입니다.


노크 법률상담은 3가지를 약속드립니다.
***1) 24시간 실시간 상담***
***2) 100% 무료***
***3) 100% 비밀보장***


아래 안내에 따라 상황을 충분히 작성하셔야 정확한 상담을 하실 수 있습니다.


첫째, ***시간 순서*** 및 ***육하원칙으로*** 작성해주세요.
둘째, ***150자 이상으로 자세히 작성***해주세요.
`;

  return (
    <ChatPageLayout>
      <ChatContainer>
        <ChatHistory>
          <Chat type="answer" focusedChatRef={focusedChatRef} content={instruction} />
          {isAdvising && (
            <>
              <Chat type="question" content={inquiry.content} />
              <Chat focusedChatRef={focusedChatRef} type="loading" content="" />
            </>
          )}
        </ChatHistory>
        <QuestionForm noBorderTop={false}>
          <QuestionInput>
            <TextareaAutosize
              maxRows={5}
              minRows={isAdvising ? 1 : 3}
              placeholder={isAdvising
                ? '답변을 생각중입니다...'
                : '1. 150자 이상으로 자세히 작성해주세요.\n2. 시간 순으로 작성해주세요.\n3. 육하원칙에 따라 작성해주세요.'}
              value={isAdvising ? '' : inquiry.content}
              disabled={isAdvising}
              onChange={handleChangeContent}
            />
            <QuestionSubmit inactive={inquiry.content === '' || isAdvising}>
              <button id="inquiry-button" type="button" onClick={consult}>
                {inquiry.content
                  ? <img src={`${assetSource}/assets/send-button.svg`} alt="보내기 버튼" />
                  : <img src={`${assetSource}/assets/send-button-inactive.svg`} alt="보내기 버튼 비활성화" />}
              </button>
            </QuestionSubmit>
          </QuestionInput>
          <TextLengthMonitor
            text={inquiry.content}
            maxLength={contentMaxLength}
            recommendedLength={recommendedContentLength}
          />
        </QuestionForm>
      </ChatContainer>
    </ChatPageLayout>
  );
}
