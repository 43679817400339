import styled from 'styled-components';
import { useEffect } from 'react';
import useQueryParams from '../hooks/useQueryParams';
import useClaimInfo from '../hooks/useClaimInfo';

import SectionTitle from '../components/common/SectionTitle';
import ClaimContent from '../components/send-claim/ClaimContent';
import assetSource from '../data/assetSource';
import PCSubmitButton from '../components/common/PCSubmitButton';
import ClaimController from '../components/send-claim/ClaimController';
import useClaimModify from '../hooks/useClaimModify';
import useResponsive from '../hooks/useResponsive';
import ClaimActionButton from '../components/ClaimActionButton';
import PageLoading from '../components/common/PageLoading';
import scrollToTop from '../utils/scrollToTop';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const Head = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

`;

const MainSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1em;
`;

export default function SendClaimPage() {
  const { loadClaimInfo, claimInfo } = useClaimInfo();
  const { doneModify } = useClaimModify();
  const { title, content, status } = claimInfo;
  const { isMobile } = useResponsive();

  const { claimId } = useQueryParams();

  useEffect(() => {
    scrollToTop();

    if (!claimId) {
      return () => {};
    }

    const loadSubscription = loadClaimInfo(claimId);

    return () => {
      if (loadSubscription) {
        loadSubscription.unsubscribe();
      }
    };
  }, [claimId, loadClaimInfo]);

  const titles = {
    init: '내용증명을 작성하고 있습니다',
    edit: '내용증명 수정하기',
    modifying: '내용증명을 작성하고 있습니다',
    send: '내용증명 보내기',
    sending: '내용증명을 발송하고 있습니다.',
    done: '내용증명 전달하기',
  };

  const isLoadingStatus = status === 'init' || status === 'modifying';

  if (status === 'sending') {
    return (
      <PageLoading />
    );
  }

  if (isMobile) {
    if (isLoadingStatus) {
      return (
        <Container>
          <ClaimContent content={content} title={title} status={status} />
          <ClaimActionButton status="isLoading" onClickRight={() => {}} />
        </Container>
      );
    }

    if (status === 'edit') {
      return (
        <Container>
          <ClaimContent content={content} title={title} status={status} />
          <ClaimController status={status} />
        </Container>
      );
    }

    return (
      <Container>
        <ClaimController status={status} />
      </Container>
    );
  }

  return (
    <Container>
      <Head>
        <SectionTitle title={titles[status]} />
        {(status === 'init' || status === 'edit' || status === 'modifying') && (
        <PCSubmitButton
          id="modify-claim-end-button"
          type="button"
          onClick={doneModify}
          inactive={isLoadingStatus}
        >
          {
            isLoadingStatus
              ? '작성 중...'
              : (
                <>
                  <img src={`${assetSource}/assets/share-icon-v2.svg`} alt="수정완료" />
                  수정 완료
                </>
              )
          }
        </PCSubmitButton>
        )}
      </Head>
      <MainSection>
        <ClaimContent content={content} title={title} status={status} />
        <ClaimController status={status} />
      </MainSection>
    </Container>
  );
}
