import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

import { ChangeEvent } from 'react';
import {
  SidebarContainer, SidebarLine, SidebarIcon as CommonSidebarIcon,
} from '../Sidebar';
import typography from '../../styles/typography';
import palette from '../../styles/palette';
import media from '../../styles/media';
import TextLengthMonitor from '../TextLengthMonitor';

const Container = styled.div`
  display: flex;
  gap: 0.5em;
`;

const SidebarIcon = styled(CommonSidebarIcon)`
  ${typography.heading2}

  ${media.isMobile`
    ${typography.heading1Mobile}
  `}
`;

const Input = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5em;
  width: 100%;
    
  h4 {
    ${typography.heading4}
    color: ${palette.textBlack};

    ${media.isMobile`
      ${typography.heading4Mobile}
    `}      
  }

  textarea {
    ${typography.body1}
    color: ${palette.textBlack};
    padding: 0;
    resize: none;
    border: none;
    outline: none;
    overflow: auto;
    width: 100%;

    ${media.isMobile`
      ${typography.body1}
    `}
  }
`;

export default function ModifyRequestInput({
  requestMessage,
  handleChange,
  requestMaxLength,
  recommendedRequestLength,
}: {
  requestMessage: string;
  handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  requestMaxLength: number;
  recommendedRequestLength: number;
}) {
  return (
    <Container>
      <SidebarContainer>
        <SidebarIcon>
          Q
        </SidebarIcon>
        <SidebarLine />
      </SidebarContainer>
      <Input>
        <h4>
          수정 요청 사항
        </h4>
        <TextareaAutosize
          cacheMeasurements
          minRows={3}
          maxRows={3}
          value={requestMessage}
          placeholder="요청 사항을 입력하세요."
          onChange={handleChange}
        />
        <TextLengthMonitor
          text={requestMessage}
          maxLength={requestMaxLength}
          recommendedLength={recommendedRequestLength}
        />
      </Input>
    </Container>
  );
}
