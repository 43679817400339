import styled from 'styled-components';

import { useEffect } from 'react';
import typography from '../styles/typography';
import palette from '../styles/palette';
import media from '../styles/media';

import ConsultationItem from '../components/consultations/ConsultationItem';

import useUserConsultations from '../hooks/useUserConsultations';

import ConsultationListItem from '../types/ConsultationListItem';
import Loading from '../components/common/PageLoading';

import assetSource from '../data/assetSource';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2em;

  ${media.isMobile`
    padding: 1.5em 1em;
  `}

  > p {
    color: ${palette.textDarkGrey};
    ${typography.body1Bold}
  }
`;

const Title = styled.header`
  ${typography.heading3}
  color: ${palette.textBlack};

  ${media.isMobile`
    ${typography.heading2Mobile}
  `}
`;

const DateConsultations = styled.section`
`;

const Date = styled.div`
  width: fit-content;
  border-bottom: 1px solid ${palette.containerLightGrey};
  display: flex;
  align-items: center;
  gap: 0.25em;
  margin-bottom: 0.5em;
  
  p {
    ${typography.heading4}
    color: ${palette.textTone};
    
    ${media.isMobile`
      ${typography.heading4Mobile}
    `}
  }

  img {
    height: 1.125em;
    width: auto;

    ${media.isMobile`
      height: 1em;
    `}
  }
`;

const Consultations = styled.ul`
  padding-block: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5em;
`;

function ConsultationGroups(
  {
    dateGroups, dates,
  }:
  {
    dateGroups: Record<string, ConsultationListItem[]>;
    dates: string[];
  },
) {
  const isEmpty = dates.length === 0;

  if (isEmpty) {
    return <p>우상단 버튼을 눌러 새로운 상담을 시작해보세요!</p>;
  }

  return dates.map((date) => (
    <DateConsultations key={date}>
      <Date>
        <img src={`${assetSource}/assets/folder.png`} alt="상담 날짜" />
        <p>{date}</p>
      </Date>
      <Consultations>
        {dateGroups[date]?.map((item) => (
          <ConsultationItem
            key={item.consultationId}
            id={item.consultationId}
            inquiry={item.inquiry}
          />
        ))}
      </Consultations>
    </DateConsultations>
  ));
}

export default function ConsultationsPage() {
  const {
    isLoading, dateGroups, dates, loadUserConsultations,
  } = useUserConsultations();

  useEffect(() => {
    loadUserConsultations();
  }, [loadUserConsultations]);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
    <Container>
      <Title>
        나의 상담기록
      </Title>
      <ConsultationGroups dateGroups={dateGroups} dates={dates} />
    </Container>
  );
}
