import styled from 'styled-components';
import typography from '../styles/typography';
import palette from '../styles/palette';
import media from '../styles/media';

import useQueryParams from '../hooks/useQueryParams';
import useKakao from '../hooks/useKakao';

import KakaoLoginButton from '../components/common/KakaoLoginButton';

import assetSource from '../data/assetSource';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8em;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${media.isMobile`
    top: 55%;
  `}
  
`;

const Logo = styled.img`
  width: 336px;
  height: auto;
  
  ${media.isMobile`
    width: 320px;
  `}
`;

const LoginSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6em;
  padding-block: 2.25em;

  ${media.isMobile`
    padding: 0;
  `}
`;

const Title = styled.div`
  
  span {
    display: block;
    text-align: center;
    color: ${palette.textBlack};
    ${typography.heading3}
  }
`;

const AgreementSection = styled.div`
  display: flex;
  gap: 1em;

  a, div {
    color: ${palette.textBlack};
    ${typography.caption}
  }

  ${media.isMobile`
    a, div {
      ${typography.caption}
    }
  `}
`;

export default function Login() {
  const { consultationId } = useQueryParams();

  const { authorize } = useKakao();

  const handleClickLogin = async () => {
    authorize({
      consultationId,
    });
  };

  return (
    <Container>
      <LoginSection>
        <Logo src={`${assetSource}/assets/logo-horizontal-big.png`} alt="노크 - AI 법률상담" />
        <Title>
          <span>
            3초만에 로그인하고
          </span>
          <span>
            무제한 무료로 질문하세요.
          </span>
        </Title>
        <KakaoLoginButton
          id="login-button"
          className="login-page"
          onClick={handleClickLogin}
        />
      </LoginSection>
      <AgreementSection>
        <a href="https://elementsworkspace.notion.site/4b4c57bdc06742c8af8b9cbadaca1eb2">이용약관</a>
        <div>|</div>
        <a href="https://elementsworkspace.notion.site/105bf7a6efb84c4eb3b9b4610939ab03">개인정보처리방침</a>
      </AgreementSection>
    </Container>
  );
}
