import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import {
  concatMap, interval, takeWhile,
} from 'rxjs';

import Message from '../types/Message';
import Inquiry from '../types/Inquiry';

import questionState from '../stores/qustionState';
import consultationState from '../stores/consultationState';

import fetchAnswer from '../service/fetchAnswer';
import createAnswer from '../service/createAnswer';
import useAuth from './useAuth';
import useLogin from './useLogin';

export interface MainDialogue {
  inquiry : Inquiry;
  advice : Message | null;
}

export default function useAdditionalConsultation() {
  const [consultation, setConsultation] = useRecoilState(consultationState);
  const [question, setQuestion] = useRecoilState(questionState);
  const { isQuestionTrialEnd } = useAuth();
  const { navigateLogin } = useLogin();
  const questionMaxLength = 200;
  const recommendedQuestionLength = 30;

  const setIsAnswering = useCallback((state: boolean) => {
    setConsultation((prev) => ({
      ...prev,
      isAnswering: state,
    }));
  }, [setConsultation]);

  const setAdditionalConsultation = useCallback((setter: (prev: Message[]) => Message[]) => {
    setConsultation((prev) => ({
      ...prev,
      additionalConsultation: setter(prev.additionalConsultation),
    }));
  }, [setConsultation]);

  const ask = useCallback(async (consultationId: string) => {
    if (isQuestionTrialEnd(consultation.additionalConsultation)) {
      navigateLogin();
      return;
    }

    setQuestion('');
    setIsAnswering(true);

    setAdditionalConsultation((prev) => [...prev, {
      type: 'question',
      content: question,
    }]);

    await createAnswer(consultationId, question);

    // short polling => 답변이 올때까지 ask
    interval(1000)
      .pipe(
        concatMap(() => fetchAnswer(consultationId)),
        takeWhile(({ isAnswering }) => isAnswering, true),
      )
      .subscribe(({ isAnswering, answer }) => {
        if (isAnswering) {
          return;
        }

        setAdditionalConsultation((prev) => [...prev, {
          type: 'answer',
          content: answer,
        }]);

        setIsAnswering(false);
      });
  }, [
    consultation.additionalConsultation,
    isQuestionTrialEnd,
    navigateLogin,
    question,
    setAdditionalConsultation,
    setIsAnswering,
    setQuestion,
  ]);

  const updateQuestion = (input :string) => {
    if (input.length > questionMaxLength) {
      const truncatedText = input.substring(0, questionMaxLength);
      setQuestion(truncatedText);
      return;
    }
    setQuestion(input);
  };

  return {
    question,
    isAnswering: consultation.isAnswering,
    messages: consultation.additionalConsultation,
    questionMaxLength,
    recommendedQuestionLength,
    ask,
    updateQuestion,
  };
}
