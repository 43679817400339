import TextareaAutosize from 'react-textarea-autosize';
import {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styled from 'styled-components';
import palette from '../styles/palette';

import useConsultation from '../hooks/useConsultation';
import useAdditionalConsultation from '../hooks/useAdditionalConsultation';

import assetSource from '../data/assetSource';

import ChatPageLayout from '../components/chat/ChatPageLayout';
import TextLengthMonitor from '../components/TextLengthMonitor';
import {
  ChatHistory, ChatContainer, QuestionForm, QuestionInput, QuestionSubmit,
} from './ChatHomePage';
import Chat from '../components/consultation/Chat';
import PageLoading from '../components/common/PageLoading';
import typography from '../styles/typography';
import useIsClaimSendable from '../hooks/useIsClaimSendable';
import media from '../styles/media';
import useHasDraftClaim from '../hooks/useHasDraftClaim';
import useAuth from '../hooks/useAuth';
import useResponsive from '../hooks/useResponsive';
import ClaimGuideDescription from '../components/consultation/ClaimGuideDescription';
import Button from '../components/common/Button';
import GreyNoticeText from '../components/common/GreyNoticeText';
import useLogin from '../hooks/useLogin';
import createClaim from '../service/createClaim';
import MobileClaimGuideSection from '../components/consultation/MobileClaimGuideSection';
import InduceLoginSection from '../components/consultation/InduceLoginSection';

const ActionContainer = styled.div`
  display: flex;
  gap: 0.5em;
  width: 100%;

  ${media.isMobile`
    gap: 0.25em;
  `}
`;

const ClaimGuideButton = styled.button<{ inactive: boolean }>`
  position: relative;
  min-width: 6em;
  padding-inline: 0.5em;
  border-radius: 25px;
  height: 48px;
  background-color: ${({ inactive }) => (inactive ? palette.containerLightGrey : palette.tint9)};
  cursor: ${({ inactive }) => (inactive ? 'default' : 'pointer')};
  pointer-events: ${({ inactive }) => (inactive ? 'none' : 'auto')};
  color: ${({ inactive }) => (inactive ? palette.textWhite : palette.primary)};

  ${typography.body1Button}

  ${media.isMobile`
    min-width: 4.5em;
    height: 44px;
    border-radius: 16px;
  `}

  img {
    width: 24px;
    aspect-ratio: 1;
  }
`;

const ClaimGuideSection = styled.div<{ hasDraftClaim: boolean }>`
  width: 17.5em;
  padding: 1.5em 1em;
  position: absolute;
  top: ${({ hasDraftClaim }) => (hasDraftClaim ? '-23.75em' : '-20.75em')}; 
  right: 30.25em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5em;

  border-radius: 10px;
  background: ${palette.containerSuperLightGrey};
`;

const SendButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ClaimLoading = styled(Button)`
  width: 100%;
  background-color: ${palette.containerLightGrey};
  color: ${palette.textWhite};
  pointer-events: none;
`;

export default function ChatPage() {
  const {
    loadConsultation,
    startLoading,
    consultation,
    isLoading,
    isAdvising,
    isAnswering,
  } = useConsultation();

  const { mainConsultation, additionalConsultation } = consultation;
  const { isQuestionTrialEnd } = useAuth();

  const {
    ask,
    updateQuestion,
    question,
    recommendedQuestionLength,
    questionMaxLength,
  } = useAdditionalConsultation();

  const focusedChatRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (focusedChatRef.current) {
      focusedChatRef.current.scrollIntoView();
    }
  }, [isAdvising, isAnswering]);

  const {
    loadHasDraftClaim,
    hasDraftClaim,
    draftClaimId,
    hasDraftClaimLoading,
  } = useHasDraftClaim();

  const {
    loadIsClaimSendable,
    isClaimSendable,
    isClaimSendableLoading,
  } = useIsClaimSendable();

  const { consultationId } = useParams();
  const { authorizeConsultation, isConsultationAuthorized, isLoggedIn } = useAuth();
  const { isMobile } = useResponsive();
  const { navigateLogin } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (!consultationId) {
      // TODO: 오류 처리
      return () => {};
    }

    const loadSubscription = loadConsultation(consultationId);

    return () => {
      if (loadSubscription) {
        loadSubscription.unsubscribe();
      }
    };
  }, [consultationId, loadConsultation]);

  const handleChangeQuestion = (event: ChangeEvent<HTMLTextAreaElement>) => {
    updateQuestion(event.target.value);
  };

  const instruction = `안녕하세요, ***AI 변호사 벤자민***입니다.


노크 법률상담은 3가지를 약속드립니다.
***1) 24시간 실시간 상담***
***2) 100% 무료***
***3) 100% 비밀보장***


아래 안내에 따라 상황을 충분히 작성하셔야 정확한 상담을 하실 수 있습니다.


첫째, ***시간 순서*** 및 ***육하원칙으로*** 작성해주세요.
둘째, ***150자 이상으로 자세히 작성***해주세요.
`;

  useEffect(() => {
    if (consultationId) {
      authorizeConsultation(consultationId);
    }
  }, [authorizeConsultation, consultationId]);

  useEffect(() => {
    if (consultationId) {
      loadHasDraftClaim(consultationId);
      loadIsClaimSendable(consultationId);
    }
  }, [loadHasDraftClaim, loadIsClaimSendable, consultationId, isMobile]);

  const [isClaimGuideOpen, setIsClaimGuideOpen] = useState<boolean>(false);

  const handleClickQuestionSubmit = () => {
    setIsClaimGuideOpen(false);
    ask(consultation.id);
  };
  const handleClickClaimGuideButton = () => {
    setIsClaimGuideOpen(!isClaimGuideOpen);
  };

  const handleClickSendClaim = async () => {
    if (!isLoggedIn()) {
      navigateLogin();
      return;
    }

    if (!consultationId) {
      return;
    }

    startLoading();
    const claimId = hasDraftClaim ? draftClaimId : await createClaim(consultationId);
    navigate(`/send-claim?claimId=${claimId}`);
  };

  const claimGuideButton = (text: string) => (
    (isAdvising || isAnswering) ? (
      <ClaimLoading disabled>답변중...</ClaimLoading>
    ) : (
      <SendButton className="start-send-claim-button" onClick={handleClickSendClaim}>
        <img src={`${assetSource}/assets/share-icon-v2.svg`} alt="보내기" />
        <span>{text}</span>
      </SendButton>
    )
  );

  const claimGuideButtonText = (isAdvising || isAnswering) ? '답변 중' : '내용증명';
  if ((isLoading || hasDraftClaimLoading || isClaimSendableLoading) && !isAdvising) {
    return (
      <PageLoading />
    );
  }

  return (
    <ChatPageLayout>
      <ChatContainer className="consultation-page">
        <ChatHistory>
          <Chat type="answer" content={instruction} />
          <Chat type="question" content={mainConsultation.inquiry.content} />
          {isAdvising
            ? <Chat type="loading" content="" focusedChatRef={focusedChatRef} />
            : (
              <Chat
                type="answer"
                content={mainConsultation.advice}
                focusedChatRef={additionalConsultation.length === 0 ? focusedChatRef : null}
              />
            )}
          {additionalConsultation.map((c, index) => (
            <Chat
              type={c.type === 'answer' ? 'answer' : 'question'}
              content={c.content}
              focusedChatRef={(index === additionalConsultation.length - 1 && c.type === 'answer') ? focusedChatRef : null}
            />
          ))}
          {isAnswering && <Chat type="loading" content="" focusedChatRef={focusedChatRef} />}
        </ChatHistory>
        <QuestionForm noBorderTop={isQuestionTrialEnd(additionalConsultation)}>
          {isQuestionTrialEnd(additionalConsultation)
          && <InduceLoginSection />}
          <ActionContainer>
            {
              (isConsultationAuthorized && isClaimSendable)
                ? (
                  <ClaimGuideButton
                    id={isClaimGuideOpen ? '' : 'open-claim-guide-button'}
                    onClick={handleClickClaimGuideButton}
                    inactive={isAdvising || isAnswering}
                  >
                    {isClaimGuideOpen
                      ? <img src={`${assetSource}/assets/tilted-plus.svg`} alt="보내기 버튼 비활성화" />
                      : claimGuideButtonText}
                    {(
                      isMobile && isClaimGuideOpen
                      && (
                        <MobileClaimGuideSection />
                      )
                    )}
                  </ClaimGuideButton>
                )
                : null
            }
            <QuestionInput>
              {
                (isConsultationAuthorized && isClaimSendable && isClaimGuideOpen)
                && (
                  <ClaimGuideSection hasDraftClaim={hasDraftClaim}>
                    <ClaimGuideDescription
                      hasDraftClaim={hasDraftClaim}
                    />
                    {claimGuideButton(hasDraftClaim ? '이어서 작성하기' : '내용증명 보내기')}
                    <GreyNoticeText>
                      ※ 좋은 퀄리티의 내용증명을 쓰기 위해 추가질문을 적어도 5회 이상 하실 것을 권장합니다.
                    </GreyNoticeText>
                  </ClaimGuideSection>
                )
              }
              <TextareaAutosize
                maxRows={3}
                minRows={1}
                placeholder={isAnswering || isAdvising ? '답변을 생각중입니다...' : '질문을 입력하세요...'}
                disabled={isAnswering || isAdvising || isQuestionTrialEnd(additionalConsultation)}
                value={question}
                onChange={handleChangeQuestion}
              />
              <QuestionSubmit inactive={isAnswering || isAdvising || question === ''}>
                <button type="button" onClick={handleClickQuestionSubmit}>
                  {question
                    ? <img src={`${assetSource}/assets/send-button.svg`} alt="보내기 버튼" />
                    : <img src={`${assetSource}/assets/send-button-inactive.svg`} alt="보내기 버튼 비활성화" />}
                </button>
              </QuestionSubmit>
            </QuestionInput>
          </ActionContainer>
          <TextLengthMonitor
            text={question}
            maxLength={questionMaxLength}
            recommendedLength={recommendedQuestionLength}
          />
        </QuestionForm>
      </ChatContainer>
    </ChatPageLayout>
  );
}
