import styled from 'styled-components';
import { Ref } from 'react';
import media from '../../styles/media';
import palette from '../../styles/palette';
import typography from '../../styles/typography';

import useAuth from '../../hooks/useAuth';
import useLogin from '../../hooks/useLogin';

import assetSource from '../../data/assetSource';

import { SidebarContainer, SidebarImage } from '../Sidebar';
import MarkdownContent from '../common/MarkdownContent';
import ProgressSection from '../answerLoading/ProgressSection';
import KakaoLoginButton from '../common/KakaoLoginButton';

const Container = styled.div<{ type: 'question' | 'answer' }>`
  display: flex;
  gap: 0.5em;
  justify-content: ${({ type }) => (type === 'answer' ? 'flex-start' : 'flex-end')};

  ${media.isMobile`
    gap: 0.25em;
  `}
`;

const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5em;

  ${media.isMobile`
    gap: 0.25em;
  `}

  h4 {
    color: ${palette.textCaptionGrey};
    ${typography.heading4}

    ${media.isMobile`
      ${typography.caption}
    `}
  }
`;

const ChatAnswer = styled(MarkdownContent)<{ expandAnimation: boolean }>`
  max-width: 40em;
  width: fit-content;
  border-radius: 12px;
  border: 1px solid ${palette.containerLightGrey};
  background-color: ${palette.containerWhite};
  padding: 0.5em 1em;
  
  ${media.isMobile`
    padding: 0.5em 0.625em;
  `}
  overflow: hidden;
  animation: ${({ expandAnimation }) => (expandAnimation ? 'expand 0.5s ease' : 'none')} ;

  @keyframes expand {
    from {
      clip-path: inset(0 0 100% 0);
      opacity: 0;
    }
    to {
      clip-path: inset(0 0 0 0);
      opacity: 1;
    }
  }
`;

const ChatQuestion = styled.p`
  max-width: 33.75em;
  width: fit-content;
  border-radius: 12px;
  background-color: ${palette.containerSuperLightGrey};
  padding: 0.5em 1em;
  ${typography.body1}
  color: ${palette.textBlack};
  white-space: pre-wrap;
  
  ${media.isMobile`
    padding: 0.5em 0.625em;
    max-width: 17.5em;
  `}
`;

const Loading = styled.div`
  width: 22.5em;
  border-radius: 12px;
  border: 1px solid ${palette.containerLightGrey};
  padding: 0.5em 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;

  section {
    min-height: auto;
  }

  ${media.isMobile`
    padding: 0.5em 0.625em;
    width: 100%;
    gap: 0.25em;
  `}
`;

const InduceLoginBox = styled.div`
  width: 100%;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 0.625em;

  h4 {
    color: ${palette.textBlack};
    ${typography.heading4}
    white-space: pre-wrap;
    text-align: center;

    ${media.isMobile`
      ${typography.heading4Mobile}
    `}
  }

  button {
    width: 100%;
  }
`;

export default function Chat({ content, type, focusedChatRef = null }:{
  content: string;
  type: 'answer' | 'question' | 'loading';
  focusedChatRef?: Ref<HTMLDivElement>;
}) {
  const { isLoggedIn } = useAuth();
  const { navigateLogin } = useLogin();

  if (type === 'answer') {
    return (
      <Container type={type} ref={focusedChatRef}>
        <SidebarContainer>
          <SidebarImage src={`${assetSource}/assets/benjamin.png`} alt="벤자민" />
        </SidebarContainer>
        <ChatContent>
          <h4>
            벤자민 - AI 변호사
          </h4>
          <ChatAnswer content={content} expandAnimation={focusedChatRef !== null} />
        </ChatContent>
      </Container>
    );
  }

  if (type === 'question') {
    return (
      <Container type={type} ref={focusedChatRef}>
        <ChatQuestion>{content}</ChatQuestion>
      </Container>
    );
  }

  return (
    <Container type="answer" ref={focusedChatRef}>
      <SidebarContainer>
        <SidebarImage src={`${assetSource}/assets/benjamin.png`} alt="벤자민" />
      </SidebarContainer>
      <ChatContent>
        <h4>
          벤자민 - AI 변호사
        </h4>
        <Loading>
          <ProgressSection type="consult" />
          {isLoggedIn() || (
          <InduceLoginBox>
            <h4>
              3초만에 로그인하고
              {'\n'}
              무제한 무료로 질문하세요
            </h4>
            <KakaoLoginButton
              id="login-button"
              className="in-consulting"
              onClick={navigateLogin}
            />
          </InduceLoginBox>
          )}
        </Loading>
      </ChatContent>
    </Container>
  );
}

Chat.defaultProps = {
  focusedChatRef: null,
};
