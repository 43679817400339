import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import requestLogin from '../service/requestLogin';
import saveAccessToken from '../service/saveAccessToken';

import useConsultation from './useConsultation';
import useVisit from './useVisit';

export default function useLogin() {
  const navigate = useNavigate();
  const { consultation } = useConsultation();
  const { guestId, removeGuestId } = useVisit();

  const login = useCallback(async (authorizationCode: string) => {
    const accessToken = await requestLogin(authorizationCode, guestId);
    removeGuestId();
    saveAccessToken(accessToken);
  }, [guestId, removeGuestId]);

  const navigateLogin = useCallback(() => {
    const consultationId = consultation.id;

    const loginQuery = [
      consultationId ? `consultationId=${consultationId}` : '',
    ].filter(Boolean).join('&');

    navigate(`/login${loginQuery ? `?${loginQuery}` : ''}`);
  }, [consultation.id, navigate]);

  return {
    login,
    navigateLogin,
  };
}
