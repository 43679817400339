import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import palette from '../../styles/palette';
import typography from '../../styles/typography';
import media from '../../styles/media';

const Container = styled(Markdown)`
  color: ${palette.textBlack};
  ${typography.body1}
  white-space: pre-wrap;

  ${media.isMobile`
    ${typography.body1}
  `}

  ul, ol {
    margin-left: 1em;
    margin-bottom: 1.5em;
    li {
      margin-bottom: 0.25em;
    }
  }

  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }

  strong {
    font-weight: 600;
    em {
      color: ${palette.primary};
    }
  }
`;

export default function MarkdownContent({ content, className = '' } :
{ content: string; className?: string; }) {
  return (
    <Container className={className}>
      {content}
    </Container>
  );
}

MarkdownContent.defaultProps = {
  className: '',
};
