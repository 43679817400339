import styled from 'styled-components';

import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import media from '../styles/media';

import ClaimDetailSection from '../components/ClaimDetailSection';
import Link from '../components/common/Link';
import assetSource from '../data/assetSource';
import useResponsive from '../hooks/useResponsive';
import useReadClaim from '../hooks/useReadClaim';
import PageLoading from '../components/common/PageLoading';
import ClaimActionButton from '../components/ClaimActionButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4em;
  align-self: stretch;
  padding-bottom: 2em;
`;

const Button = styled(Link)`
  display: flex;
  gap: 0.625em;
  align-items: center;

  ${media.isMobile`
    display: none;
  `}
`;

export default function ReadClaimPage() {
  const { isMobile } = useResponsive();
  const { claimId } = useParams();
  const navigate = useNavigate();

  const { loadClaimDetail, claimDetail } = useReadClaim();

  useEffect(() => {
    if (!claimId) {
      // TODO: 오류 처리
      return;
    }

    loadClaimDetail(claimId);
  }, [claimId, loadClaimDetail]);

  if (!claimDetail.content) {
    return <PageLoading />;
  }

  if (isMobile) {
    return (
      <Container className="read-claim-page">
        <ClaimDetailSection
          claimDetail={claimDetail}
        />
        <ClaimActionButton status="isClaimDetailPage" onClickRight={() => navigate('/?status=newConsultation')} />
      </Container>
    );
  }

  return (
    <Container className="read-claim-page">
      <ClaimDetailSection
        claimDetail={claimDetail}
      />
      <Button id="inquiry-me-too-button" href="/">
        <img src={`${assetSource}/assets/pen_white.svg`} alt="질문 제출하기" />
        <span>내 사건에 대해 무료법률상담 받기</span>
      </Button>
    </Container>
  );
}
