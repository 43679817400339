import styled from 'styled-components';

import { Outlet } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';

import media from '../../styles/media';

const Container = styled.div`
// header size => todo. 헤더사이즈를 dynamic 하게 반영.
  margin-top: 3.75em;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.isMobile`
    margin-top: 3.375em;
  `}
`;

const MainLayout = styled.div`
  min-height: 80svh;
  width: 100%;
  max-width: 68em;
  padding-block: 2em;

  ${media.isMobile`
    padding: 0;
  `}
`;

export default function PageLayout({ withFooter = true, isPcHomepage, isSendClaimPage }: {
  withFooter: boolean;
  isPcHomepage: boolean;
  isSendClaimPage: boolean;
}) {
  return (
    <Container>
      <Header isPcHomepage={isPcHomepage} isSendClaimPage={isSendClaimPage} />
      <MainLayout>
        <Outlet />
      </MainLayout>
      {withFooter && <Footer />}
    </Container>
  );
}
