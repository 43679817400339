import styled from 'styled-components';

import palette from '../../styles/palette';
import media from '../../styles/media';
import typography from '../../styles/typography';

import {
  AnswerSidebar,
} from '../Sidebar';
import ClaimShareButton from '../common/ClaimShareButton';
import ClaimActionButton from '../ClaimActionButton';

import useQueryParams from '../../hooks/useQueryParams';
import useClaimShare from '../../hooks/useClaimShare';
import useResponsive from '../../hooks/useResponsive';
import MarkdownContent from '../common/MarkdownContent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25em;
  padding: 1.5em 1em;
  border-radius: 10px;
  border: 1px solid ${palette.containerLightGrey};
  gap: 1.5em;

  ${media.isMobile`
    width: 100vw;
    padding: 0.5em 0.75em;
    border-radius: 0;
    border: none;
  `}
`;

const Instruction = styled.div`
  display: flex;
  gap: 0.5em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5em;

  white-space: pre-wrap;

  h4 {
    color: ${palette.textBlack};
    ${typography.heading4}

    ${media.isMobile`
      ${typography.heading4Mobile}
    `}
  }

  p {
    color: ${palette.textBlack};
    ${typography.body1}

    ${media.isMobile`
      ${typography.body1}
    `}
  }
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

export default function ClaimShare() {
  const { hasPhoneNumber } = useQueryParams();
  const { kakaoShare, selfShare, mobileWebShare } = useClaimShare();
  const { isMobile } = useResponsive();

  const guideMessage = hasPhoneNumber === 'true'
    ? '일단 제가 직접 내용증명을 상대방에게 발송하였습니다.'
    : '그러나 상대방의 전화번호가 없어 ***제가 발송하지는 못했습니다.***';

  const content = `고생하셨습니다.\n\n\n지금부터 관건은 상대방이 이 문서를 ***어떻게든 받게 하는 겁니다.***\n\n\n${guideMessage}\n\n\n아래 버튼을 눌러 카카오톡이나 인스타그램 DM 등으로 ***상대방에게 직접 내용증명을 전달하세요.***\n\n\n상대방이 문서를 받고 읽을 가능성이 높아질수록 스스로 항복할 가능성도 높아집니다.`;

  return (
    <Container>
      <Instruction>
        <AnswerSidebar />
        <Content>
          <h4>벤자민 - AI 변호사</h4>
          <MarkdownContent content={content} />
        </Content>
      </Instruction>
      {isMobile
        ? <ClaimActionButton status="shareClaim" onClickLeft={kakaoShare} onClickRight={mobileWebShare} />
        : (
          <ButtonArea>
            <ClaimShareButton shareTo="kakao" onClick={kakaoShare} />
            <ClaimShareButton shareTo="self" onClick={selfShare} />
          </ButtonArea>
        )}
    </Container>
  );
}
